import { experteBlockungQuelle, geschaeftsbereiche, resourcenBlockungTypes, type StandortSlot } from '../../../../../../../dtos';
import { type TerminInfo } from '../../../../../../../utils/TerminInfo';
import { CalendarSlotEvent } from '../CalendarEvent';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { addDays, eachDayOfInterval, isWithinInterval, max, min } from 'date-fns';
import { v4 } from 'uuid';

export const newSlot = {
  id: 's-createslot',
  type: resourcenBlockungTypes.SLOT,
  name: 'Klicke zum Hinzufügen eines neuen Standort-Slots in die Kalenderzeile.',
  toEvents: () => [],
} as unknown as CalendarSlotResource;

export class CalendarSlotResource extends AbstractCalendarResource {
  public readonly type = resourcenBlockungTypes.SLOT;

  public readonly id: `s-${string}`;

  public readonly name: string;

  public readonly data: Partial<StandortSlot> & { standortId: number; standortName: string };

  public readonly subline: string;

  public readonly isNew: boolean = false;

  public constructor(terminInfo: TerminInfo, data: Partial<StandortSlot> & { standortId: number; standortName: string }) {
    super(terminInfo);
    this.isNew = !data.id;
    this.data = data;
    this.id = `s-${data.id ?? v4()}`;
    this.name = `${data.standortName} - Slot ${data.id ?? 'neu'}`;
    this.subline = '(Gruppenraum, Im Grünen, 61qm)';
  }

  public toEvents(): CalendarSlotEvent[] {
    const events: CalendarSlotEvent[] = [];

    for (const blockung of this.data.blockungen ?? []) {
      const start = blockung.start;
      const end = blockung.end;

      const event = events.find((ev) => ev.data.veranstaltungId === String(blockung.veranstaltungId));
      if (event) {
        event.start = min([event.start, start]);
        event.end = max([event.end, end]);
        event.data.ablauf.push({ start, end });
      } else {
        const newEvent = new CalendarSlotEvent(blockung.start, blockung.end, this, {
          division: geschaeftsbereiche.KFF,
          quelle: experteBlockungQuelle.VAMOS,
          veranstaltungId: String(blockung.veranstaltungId),
          raumgroesse: blockung.raumgroesse ?? 0,
          gruppenraum: blockung.gruppenraum,
          hotelqualifikationen: blockung.hotelqualifikationen ?? '',
        });

        const machSapid = blockung.veranstaltungId === Number(this.terminInfo.sapid);
        const matchid = blockung.veranstaltungId === Number(this.terminInfo.id);
        newEvent.isOldSelection = matchid || machSapid;

        events.push(newEvent);
      }
    }

    return events;
  }

  public createEvent(date: Date): CalendarSlotEvent {
    const end = addDays(date, this.terminInfo.ablauf.length - 1);
    const event = new CalendarSlotEvent(date, end, this, {
      raumgroesse: 0,
      gruppenraum: false,
      hotelqualifikationen: '',
      veranstaltungId: String(this.terminInfo.id ?? ''),
      quelle: experteBlockungQuelle.VAMOS,
      division: this.terminInfo.division,
    });

    return event;
  }

  public getDataId(): number {
    return this.data.id ?? -1;
  }

  public isAvailable(interval: { start: Date; end: Date }): boolean {
    if (this.isNew) {
      return true;
    }

    return eachDayOfInterval(interval).every((day) =>
      isWithinInterval(day, {
        start: this.data.start ?? new Date(),
        end: this.data.end ?? new Date(),
      }),
    );
  }
}
